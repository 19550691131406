import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, notification, Typography } from "antd";
import { PhoneOutlined, BarcodeOutlined } from "@ant-design/icons";
import { adminSendOtp } from "../api/api";
import { loginAdmin } from "../Actions/authAction";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/logo.png";

const { Text } = Typography;

export default function Login() {
  const loginAdminDispatch = useDispatch();
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const state = useSelector((state) => state.auth);

  useEffect(() => {
    if (state.isAuthenticated) {
      history.push("/");
    }
  }, [state, history]);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const onFinish = (values) => {
    let { phone, otp } = values;
    if (!otpSent) {
      adminSendOtp({ phone: phone }, afterAsync);
    } else {
      if (phone && otp) {
        loginAdminDispatch(
          loginAdmin(
            {
              phone: phone,
              otp: parseInt(otp),
            },
            history,
            loginAfterFunction
          )
        );
      }
    }
    setLoading(true);
  };

  const afterAsync = (result) => {
    setLoading(false);
    if (result && !otpSent) {
      setOtpSent(true);
    } else {
      openNotificationWithIcon(
        "error",
        "Failed to send otp",
        "Please try again with a valid phone number."
      );
    }
  };
  const loginAfterFunction = (result) => {
    setLoading(false);
    openNotificationWithIcon(
      "error",
      "Failed to login",
      "Sms verification failed"
    );
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  return (
    <div className="loginContainer">
      <Card className="loginFormContainer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <img src={logo} style={{ width: "200px" }} alt="" />
        </div>
        <p>WELCOME TO ONESALEMART Admin</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number!",
              },
            ]}
          >
            <Input
              prefix={<PhoneOutlined style={{ marginRight: "0.5rem" }} />}
              placeholder="Phone"
              size="large"
            />
          </Form.Item>
          {otpSent && (
            <Form.Item
              label="OTP Code"
              name="otp"
              style={otpSent ? { height: "auto" } : { height: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Please insert OTP!",
                },
              ]}
            >
              <Input
                prefix={<BarcodeOutlined style={{ marginRight: "0.5rem" }} />}
                type="number"
                placeholder="OTP Code"
                size="large"
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              style={{ marginTop: "0.5rem" }}
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
            >
              {otpSent ? "Confirm Submission" : "Send Verification"}
            </Button>
          </Form.Item>
        </Form>
        <Text
          type="error"
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => setOtpSent(true)}
        >
          Already has an OTP ? Click Here
        </Text>
      </Card>
    </div>
  );
}
