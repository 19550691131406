import axios from "axios";
import jwt_decode from "jwt-decode";
import { logout } from "../api/api";
import setAuthToken from "../utils/setAuthToken";
import url from "../utils/url";

export const loginAdmin = (data, history, loginAfterFunction) => (dispatch) => {
  axios
    .post(url + "/admin/auth", data)
    .then((res) => {
      if (res.data && res.data.access_token) {
        // console.log(jwt_decode(res.data.access_token));
        const { adminId, adminDept, adminRole, adminPhone, adminName } =
          jwt_decode(res.data.access_token);
        localStorage.setItem("zz", res.data.access_token);
        localStorage.setItem(
          "pr",
          JSON.stringify({
            adminId,
            adminDept,
            adminRole,
            adminPhone,
            adminName,
          })
        );
        setAuthToken(res.data.access_token);
        dispatch(setAdmin(true));
        dispatch(
          setAdminProfile({
            adminId,
            adminDept,
            adminRole,
            adminPhone,
            adminName,
          })
        );
        // console.log('adminRole', adminRole)
        window.location = "/orders";
      }
    })
    .catch((err) => {
      console.log(err.response);
      loginAfterFunction(false);
    });
};

export const logoutAdmin = () => (dispatch) => {
  
  logout();
  localStorage.removeItem("zz");
  localStorage.removeItem("pr");
  setAuthToken(null);
  dispatch(setAdmin(false));
};

export const setAdmin = (isAuthenticated) => {
  return {
    type: "SET_ADMIN_AUTH",
    payload: isAuthenticated,
  };
};
export const setAdminProfile = (info) => {
  return {
    type: "SET_ADMIN_PROFILE",
    payload: info,
  };
};
export const setAdminRole = (data) => {
  return {
    type: "SET_ADMIN_ROLES",
    payload: data,
  };
};
