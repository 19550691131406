import { combineReducers } from "redux";
import authReducer from "./authReducer";
import roleReducer from "./roleReducer";
import configReducer from "./configReducer";

export default combineReducers({
  auth: authReducer,
  roles: roleReducer,
  config: configReducer,
});
