import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    if (token.includes("public")) {
      axios.defaults.headers.common["public_token"] =
        "Bearer " + token.substring(6);
    }
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
