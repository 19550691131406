export const isPermitted = (permissions, allowed) => {

  // console.log('permissions', permissions)
  let perm = false;
  if (allowed?.includes("all")) {
    return true;
  }
  allowed?.length > 0 &&
    allowed.forEach((element) => {
      if (permissions && permissions.includes(element)) {
        perm = true;
      }
    });
  // console.log('perm', perm)
  return perm;
};
