import {
  GoldOutlined,
  TagsOutlined,
  TeamOutlined,
  CarOutlined,
  DollarOutlined,
  NotificationOutlined,
  AccountBookOutlined,
  BankOutlined,
  StepForwardOutlined,
  PlusCircleOutlined,
  TransactionOutlined,
  HomeOutlined,
  MoneyCollectOutlined,
  CalculatorOutlined,
  DeliveredProcedureOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";

export const sidebarItems = [
  {
    name: "Dashboard",
    path: "/",
    icon: <HomeOutlined />,
    permissions: ["admin", "frontdesk", "cs", "purchasing"],
    dev: true,
    production: true,
  },
  {
    name: "Customers",
    path: "/customers",
    icon: <TeamOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["all"],
    dev: true,
    production: true,
    // ["admin", "frontdesk", "cs","accounting"],
  },
  {
    name: "Orders",
    path: "/orders",
    icon: <GoldOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["all"],
    dev: true,
    production: true,
  },
  {
    name: "Refund",
    path: "/refund",
    icon: <FundProjectionScreenOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin", "accounting"],
    dev: true,
    production: true,
  },

  {
    name: "Balance",
    path: "/balance",
    icon: <MoneyCollectOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin", "accounting","storemanager"],
    dev: true,
    production: true,
  },
  {
    name: "Fees",
    path: "fees",
    icon: <DollarOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin"],
    dev: true,
    production: true,
  },
  {
    name: "Status",
    path: "/status",
    icon: <NotificationOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin"],
    dev: true,
    production: true,
  },
  {
    name: "Purchasing",
    path: "/purchasing",
    icon: <PlusCircleOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","purchasing","cpo"],
    dev: true,
    production: true,
  },
  {
    name: "Courier",
    path: "/courier",
    icon: <CarOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","store","accounting","tracking"],
    dev: true,
    production: false,
  },

  /////
  {
    name: "Coupon",
    path: "/coupon",
    icon: <AccountBookOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","marketing"],
    dev: true,
    production: true,
  },
  {
    name: "Transactions",
    path: "/transactions",
    icon: <TransactionOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin"],
    dev: true,
    production: true,
  },
  {
    name: "Tracking",
    path: "/tracking",
    icon: <StepForwardOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","tracking"],
    dev: true,
    production: true,
  },
  {
    name: "Bank slip",
    path: "/bank-slip",
    icon: <BankOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","accounting"],
    dev: true,
    production: true,
  },
  {
    name: "Invoice",
    path: "/invoice",
    icon: <CarOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","store","storemanager"],
    dev: true,
    production: true,
  },
  ///
  {
    name: "Tickets",
    path: "/tickets",
    icon: <TagsOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["all"],
    dev: true,
    production: true,
  },
  // {
  //   name: "Delivery",
  //   path: "/delivery",
  //   icon: (
  //     <DeliveredProcedureOutlined style={{ fontSize: "14px", width: "18px" }} />
  //   ),
  //   permissions: ["admin","accounting"],
  // },
  {
    name: "Cash Counter",
    path: "/cash-counter",
    icon: <CalculatorOutlined style={{ fontSize: "14px", width: "18px" }} />,
    permissions: ["admin","accounting","storemanager"],
    dev: true,
    production: false,
  },
];
