import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.less";
import { Empty, Layout } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";

import { Provider } from "react-redux";
import Login from "./pages/Login";
import AdminRoute from "./routes/AdminRoute";
import setAuthToken from "./utils/setAuthToken";
import { setAdmin, setAdminProfile, setAdminRole } from "./Actions/authAction";
import SideBar from "./layouts/SideBar";
import Navbar from "./layouts/NavBar";
// import Refund from "./pages/Refund";

// const InvoicePageDetails = lazy(() => import("./pages/InvoicePageDetails"));
// const InvoiceDetailsPagePrint = lazy(() =>
//   import("./pages/InvoiceDetailsPagePrint")
// );

// import Invoice from "./components/Invoice";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Refund = lazy(() => import("./pages/Refund"));
const Orders = lazy(() => import("./pages/Orders"));
const Transaction = lazy(() => import("./pages/Transaction"));
const Customers = lazy(() => import("./pages/Customers"));
const Delivery = lazy(() => import("./pages/Delivery"));
const DeliveryMethod = lazy(() => import("./pages/DeliveryMethod"));
const DeliveryHubComplete = lazy(() => import("./pages/DelivreyHubComplete"));
const CashCounter = lazy(() => import("./pages/CashCounter"));
const Balance = lazy(() => import("./pages/Balance"));
const InvoicePage = lazy(() => import("./pages/InvoicePage"));
const Roles = lazy(() => import("./pages/Roles"));
const CustomerDetails = lazy(() => import("./pages/CustomerDetails"));
const Invoice = lazy(() => import("./pages/Invoice"));
const Tracking = lazy(() => import("./pages/Tracking"));
const Purchase = lazy(() => import("./pages/Purchase"));
const Tickets = lazy(() => import("./pages/Tickets"));
const BankSlip = lazy(() => import("./pages/BankSlip"));
const Coupon = lazy(() => import("./pages/Coupon"));
const OrderDetails = lazy(() => import("./pages/OrderDetails"));

const StatusPage = lazy(() => import("./pages/StatusPage"));
// const Status = lazy(() => import("./pages/Status"));
const Category = lazy(() => import("./pages/Category"));
const Fee = lazy(() => import("./pages/Fee"));
const Employees = lazy(() => import("./pages/Employees"));
const Profile = lazy(() => import("./pages/Profile"));
const InvoicePageDetails = lazy(() => import("./pages/InvoicePageDetails"));
const InvoiceDetailsPagePrint = lazy(() =>
  import("./pages/InvoiceDetailsPagePrint")
);

if (localStorage.zz) {
  setAuthToken(localStorage.zz);
  store.dispatch(setAdmin(true));
}

if (localStorage.pr) {
  let profile = JSON.parse(localStorage.pr);
  store.dispatch(setAdminProfile(profile));
  store.dispatch(setAdminRole(profile.adminRole));
}

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const profile = localStorage.getItem("pr");
  let profileData = profile ? JSON.parse(profile) : null;
  // console.log('profileData', profileData)
  const [trigger, settrigger] = useState(false);
  const roles = store.getState().roles ? store.getState().roles.roles : [];
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <div className="App">
            <Layout>
              <SideBar state={{ show, setshow }} />
              <Layout>
                <Navbar state={{ settrigger, setshow, show }} />
                <Switch location={window.location}>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "frontdesk", "cs", "purchasing"]}
                        Component={<Dashboard />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/orders"
                    render={() => (
                      <AdminRoute
                        permission={["all"]}
                        state={{ show, trigger }}
                        Component={<Orders />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/transactions"
                    render={() => (
                      <AdminRoute
                        permission={["admin", "tracking"]}
                        state={{ show, trigger }}
                        Component={<Transaction />}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/customers"
                    render={() => (
                      <AdminRoute
                        permission={["all"]}
                        // {["admin", "cs","frontdesk","accounting"]}
                        state={{ show, trigger }}
                        Component={<Customers />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/delivery"
                    render={() => (
                      <AdminRoute
                        permission={["admin"]}
                        state={{ show, trigger }}
                        Component={<Delivery />}
                      />
                    )}
                  />
                  {/* {window.location.hostname === "localhost" && ( */}
                    <Route
                      exact
                      path="/courier"
                      render={() => (
                        <AdminRoute
                          permission={["admin","accounting","tracking","store"]}
                          state={{ show, trigger }}
                          Component={<DeliveryMethod />}
                        />
                      )}
                    />
                  {/* )} */}
                  <Route
                    exact
                    path="/delivery-complete/:uid/:sid"
                    render={() => (
                      <AdminRoute
                        permission={["admin"]}
                        state={{ show, trigger }}
                        Component={<DeliveryHubComplete />}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/customers/:id"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "cs", "frontdesk", "all"]}
                        Component={<CustomerDetails />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/status"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin"]}
                        Component={<StatusPage />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/fees"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin"]}
                        Component={<Fee />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/purchasing"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "purchasing", "cpo"]}
                        Component={<Purchase />}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/tracking"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "tracking"]}
                        Component={<Tracking />}
                      />
                    )}
                  />
                  {window.location.hostname === "localhost" && (
                    <Route
                      exact
                      path="/tickets"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          permission={["all"]}
                          Component={<Tickets />}
                        />
                      )}
                    />
                  )}

                  <Route
                    exact
                    path="/balance"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "accounting", "storemanager"]}
                        Component={<Balance />}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/invoice"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "store", "storemanager"]}
                        Component={<InvoicePage />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/invoice/details/:userID/:statusID"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "store", "storemanager"]}
                        Component={<InvoicePageDetails />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/invoice/details/print/:userID/:statusID"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "store", "storemanager"]}
                        Component={<InvoiceDetailsPagePrint />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/invoice/:id"
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        permission={["admin", "store", "storemanager"]}
                        Component={<Invoice />}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/category"
                    render={() => (
                      <AdminRoute
                        permission={["admin"]}
                        state={{ show, trigger }}
                        Component={<Category />}
                      />
                    )}
                  />
                  {window.location.hostname === "localhost" && (
                    <Route
                      exact
                      path="/cash-counter"
                      render={() => (
                        <AdminRoute
                          permission={["admin", "accounting",'storemanager']}
                          state={{ show, trigger }}
                          Component={<CashCounter />}
                        />
                      )}
                    />
                  )}

                  <Route
                    exact
                    path="/profile"
                    render={() => (
                      <AdminRoute
                        permission={["all"]}
                        state={{ show, trigger }}
                        Component={<Profile />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/order/:id"
                    render={() => (
                      <AdminRoute
                        permission={["all"]}
                        state={{ show, trigger }}
                        Component={<OrderDetails />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/bank-slip"
                    render={() => (
                      <AdminRoute
                        permission={["admin", "accounting"]}
                        state={{ show, trigger }}
                        Component={<BankSlip />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/coupon"
                    render={() => (
                      <AdminRoute
                        permission={["admin", "marketing"]}
                        state={{ show, trigger }}
                        Component={<Coupon />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/refund"
                    render={() => (
                      <AdminRoute
                        permission={["admin", "accounting"]}
                        state={{ show, trigger }}
                        Component={<Refund />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/employees"
                    render={() => (
                      <AdminRoute
                        permission={["admin"]}
                        state={{ show, trigger }}
                        Component={<Employees />}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/roles"
                    render={() => (
                      <AdminRoute
                        permission={["admin"]}
                        state={{ show, trigger }}
                        Component={<Roles />}
                      />
                    )}
                  />

                  <Route exact path="/login" render={() => <Login />} />
                  <Route
                    render={() => (
                      <AdminRoute
                        state={{ show, trigger }}
                        Component={
                          <div
                            style={{
                              height: "80vh",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Empty description="404 Not Found" />
                          </div>
                        }
                      />
                    )}
                  />
                </Switch>
              </Layout>
            </Layout>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
