const initialState = {
  roles: [],
};
export default function adminRole(state = initialState, action) {
  switch (action.type) {
    case "SET_ADMIN_ROLES":
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
}
