import axios from "axios";
import url from "../utils/url";

export const setConfiguration = (data) => (dispatch) => {
  axios
    .get(url + "/admin/config", data)
    .then((res) => {
      dispatch(setConfig(res.data));
    })
    .catch((err) => {});
};

export const setConfig = (data) => {
  return {
    type: "SET_CONFIG",
    payload: data,
  };
};

export const setRoleNow = (data) => {
  // console.log('data', data)
  return {
    type: "SET_ROLE_NOW",
    payload:data
  };
}
