import axios from "axios";
import moment from "moment";
import getError from "../utils/ErrorHandler";
import url from "../utils/url";

axios.defaults.headers["azobit"] = "404";

export const adminSendOtp = (data, afterAsync) => {
  axios
    .post(url + `/admin/auth`, data)
    .then((res) => {
      console.log(res.data);
      afterAsync(true);
    })
    .catch((err) => {
      // console.log(err.response);
      afterAsync(false);
    });
};
export const adminSendSms = (data, afterAsync) => {
  axios
    .post(url + `/admin/send-sms`, data)
    .then((res) => {
      // console.log(res.data);
      afterAsync(true);
    })
    .catch((err) => {
      // console.log(err.response);
      afterAsync(false);
    });
};

export const getOrders = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  // console.log("filters", filters);
  axios
    .get(url + "/admin/orders" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};

export const addOrderTracking = (data, afterAsync) => {
  axios
    .post(url + `/admin/order-tracking`, data)
    .then((res) => {
      // console.log(res.data);
      afterAsync(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterAsync(false);
    });
};

export const deleteOrderTracking = (data, afterAsync) => {
  axios
    .delete(url + `/admin/order-tracking`, { data })
    .then((res) => {
      // console.log(res.data);
      afterAsync(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterAsync(false);
    });
};

export const addOrderFee = (data, result) => {
  axios
    .post(url + "/admin/order-fee", data)
    .then((res) => {
      result(true);
    })
    .catch((err) => {
      result(false);
    });
};

export const getSingleOrder = (id, setData) => {
  axios
    .get(url + "/admin/order/" + id)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getPurchasingOrdersByPage = (
  page,
  setData,
  setLoading,
  filter
) => {
  console.log(filter);
  axios
    .post(url + `/admin/purchase/search?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};
export const getTrackingOrdersByPage = (page, setData, setLoading, filter) => {
  console.log(filter);
  axios
    .post(url + `/admin/tracking/search?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};

export const getSortingOrdersByPage = (page, setData, setLoading, filter) => {
  console.log(filter);
  axios
    .post(url + `/admin/sorting/search?page=${page}`, filter)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};

export const getCouriers = (setData, page) => {
  axios
    .get(url + `/admin/courier?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getReport = (type, setData) => {
  axios
    .get(url + `/report/${type}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCustomerByPhone = (platform, phone, setData) => {
  axios
    .get(url + `/customers/${platform}?phone=${phone}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCustomerList = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/customers" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getSingleCustomer = (id, setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/customer/${id}` + filters)
    .then((res) => {
      setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCustomerOrder = (id, setData, page) => {
  axios
    .post(url + `/admin/customer-order?page=${page}`, { user_id: id })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};
export const getCustomerBalanceTransaction = (id, setData, page) => {
  let pageNum = page ? page : 1;
  axios
    .post(url + `/admin/customer-balance-transaction?page=${pageNum}`, {
      user_id: id,
    })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};
export const getCustomerOderTransaction = (id, setData, page) => {
  let pageNum = page ? page : 1;
  axios
    .post(url + `/admin/customer-order-payments?page=${pageNum}`, {
      user_id: id,
    })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};

export const addTrackingNote = (data, afterPerform) => {
  axios
    .post(url + `/admin/tracking/order-note`, data)
    .then((res) => {
      console.log(res.data);
      afterPerform(true, "Order Note ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Order Note ");
    });
};
export const addToCourier = (data, afterPerform) => {
  axios
    .post(url + `/admin/courier`, data)
    .then((res) => {
      afterPerform(true, "Order");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Order");
    });
};

export const approveBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/approve-bankpay/${id}`)
 
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};
export const denyBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/deny-bankpay/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};
export const approveOrderBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/accountant/approve-payment/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};
export const denyOrderBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/accountant/deny-payment/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};

export const getBankBalanceDeposit = (page, setData, setLoading, filter) => {
  let sort = filter ? filter : "";
  let pageNum = filter ? 0 : page;
  console.log(sort);
  axios
    .get(url + `/admin/accountant/balance-deposits?page=${pageNum}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getBankPaymentDeposit = (page, setData, setLoading, filter) => {
  let sort = filter ? filter : "";
  let pageNum = filter ? 0 : page;
  console.log(sort);
  axios
    .get(url + `/admin/accountant/bank-payments?page=${pageNum}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCoupons = (page, setData) => {
  axios
    .get(url + `/admin/coupons?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getRoles = (setData, afterPerform) => {
  axios
    .get(url + `/admin/roles`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
      if (afterPerform && res.data.roles && res.data.roles.length > 0) {
        let id = res.data.roles[0].id;
        afterPerform(id);
      }
    })
    .catch((err) => {
      // console.log('err', err)
      setData([]);
    });
};
export const getPermissions = (setData) => {
  axios
    .get(url + `/admin/permissions`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getRoleById = (id, setData) => {
  axios
    .get(url + `/admin/roles/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getFees = (page, setData) => {
  axios
    .get(url + `/admin/fees?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getDeliveryBookings = (page, setData) => {
  axios
    .get(url + `/admin/delivery-bookings?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getStatus = (page = 1, setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  // console.log("filters", filters);
  let param = filters ? filters : `?page=${page}`;
  axios
    .get(url + `/admin/statuses${param}`)
    .then((res) => {
      setData(res.data);
      
    })
    .catch((err) => {
      setData([]);
    });
};

export const getAllStatus = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  // console.log("filters", filters);
  axios
    .get(url + "/admin/status" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};

export const updateFee = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/fee/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addFee = (data, afterPerform) => {
  axios
    .post(url + `/admin/fee`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addCustomer = (data, afterPerform) => {
  axios
    .post(url + `/admin/customer`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateStatus = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/status/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateCustomer = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/customer/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateShipment = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/shipment/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateScn = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/scn/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const addStatus = (data, afterPerform) => {
  axios
    .post(url + `/admin/status`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const getAdminCategories = (page, setData) => {
  axios
    .get(url + `/admin/categories?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getCategories = (setData) => {
  axios
    .get(url + `/categories`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getLeaves = (setData, filter) => {
  let finalUrl = url + `/leaves`;
  if (filter) {
    finalUrl = url + `/leaves` + filter;
  }
  axios
    .get(finalUrl)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getLeaveStats = (setData, filter) => {
  let finalUrl = url + `/leavestats`;
  if (filter) {
    finalUrl = url + `/leavestats` + filter;
  }
  axios
    .get(finalUrl)
    .then((res) => {
      if (res.data.result) {
        let obj = {};
        res.data.result.forEach((el) => {
          obj[el.leave] = el.day_count;
        });
        setData(obj);
      } else {
        setData(null);
      }
    })
    .catch((err) => {
      setData(null);
    });
};
export const getTxnCategories = (setData, page, filter) => {
  let ext = `/txn-categories`;
  if (filter) {
    ext = `/txn-categories?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getHolidays = (setData, filter) => {
  let ext = `/holidays`;
  if (filter) {
    ext = `/holidays?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const attendanceSummery = (setData, filter) => {
  let ext = `/attendance/summery`;
  if (filter) {
    ext = `/attendance/summery?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
// export const getTags = (setData) => {
//   axios
//     .get(url + `/tags`)
//     .then((res) => {
//       setData(res.data);
//     })
//     .catch((err) => {
//       setData([]);
//     });
// };

export const getTags = (setData, setLoading, filter) => {
  let ext = `/tags`;
  if (filter) {
    ext = `/tags?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);

      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getDepartments = (setData) => {
  setData([
    "admin",
    "IT",
    "marketing",
    "accounting",
    "purchasing",
    "graphics",
    "tracking",
    "delivery",
    "customer care",
    "intern",
  ]);
};

export const getPurchasingStats = (setData) => {
  axios
    .get(url + `/admin/purchase/stats`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getDeliveryOrders = (page, setData) => {
  axios
    .get(url + `/admin/delivery/stats?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getDeliveryList = (page, setData) => {
  axios
    .get(url + `/admin/delivery-list?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getPurchasingOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/orders?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getTrackingOrders = (page, setData, status) => {
  let urlExt = status
    ? `/admin/tracking/orders?page=${page}&status=${status}`
    : `/admin/tracking/orders?page=${page}`;
  axios
    .get(url + urlExt)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getMyPurchasingOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/my-orders?page=${page}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getRefunds = (page, setData) => {
  axios
    .get(url + `/admin/refund-list?page=${page}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getMyCompletedOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/my-completed-orders?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSupportCategories = (page, setData) => {
  axios
    .get(url + `/admin/support/category?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};


export const getInvoices = (page, setData, filter) => {
  // console.log('filter', filter)
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/delivery-list?page=${page}` + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getInvoicesDelivery = (setData, filter) => {
  // console.log('filter', filter)
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/invoices` + filters)
    .then((res) => {
      if (res.data && res.data) setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};


export const swapDeliveryStatus = (orderIDs, currentStatus, afterPerform) => {
  console.log("hit");
  axios
    .post(url + `/admin/sorting/switch`, {
      status_id: currentStatus,
      order_ids: orderIDs,
    })
    .then((res) => {
      console.log(res.data);
      if (res.data) {
        afterPerform(currentStatus === 19 ? 18 : 19);
      }
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateTracking = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/tracking/orders/${id}`, data)
    .then((res) => {
      afterPerform(true, "Tracking ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Tracking ");
    });
};
export const updateCourier = (id, filter, afterPerform) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  console.log('filters', filters)
  axios
    .put(url + `/admin/update-invoice/${id}` + filters)
    .then((res) => {
      afterPerform(true,);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const sendRefund = (data, afterPerform) => {
  axios
    .post(url + `/admin/refund-orders`, data)
    .then((res) => {
      afterPerform(true, "Tracking ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Tracking ");
    });
};


export const deleteTracking = (id, afterPerform) => {
  axios
    .delete(url + `/admin/tracking/orders/${id}`)
    .then((res) => {
      console.log(res.data);
      afterPerform(true, "Tracking ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Tracking ");
    });
};

export const updateAdminUserPermission = (
  data,
  page,
  setData,
  afterPerform
) => {
  axios
    .post(url + `/admin/assign-staff-role`, data)
    .then((res) => {
      console.log(res.data);
      getUsers(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const debitCreditTransaction = (
  data,
  filter,
  setCustomer,
  afterPerform
) => {
  axios
    .post(url + `/admin/create-balance-transaction`, data)
    .then((res) => {
      getCustomers(0, setCustomer, () => {}, filter);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addBookingFee = (data, afterPerform) => {
  axios
    .post(url + `/admin/booking-fee`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const deleteOrderFee = (id, order_id, setData, afterPerform) => {
  axios
    .delete(url + `/admin/order-fee/${id}`)
    .then((res) => {
      getOrderById(order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateOrderFee = (id, order_id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/order-fee/${id}`, data)
    .then((res) => {
      getOrderById(order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateOrderItem = (id, order_id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/update-order-item/${id}`, data)
    .then((res) => {
      getOrderById(order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addCoupon = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/coupon`, data)
    .then((res) => {
      getCoupons(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const assignOrderPurchaseManager = (data, afterPerform) => {
  axios
    .post(url + `/admin/purchase/assign-manager`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addAdminPayment = (data, setData, afterPerform) => {
  axios
    .post(url + `/admin/order-payment`, data)
    .then((res) => {
      getOrderById(data.order, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      // console.log(err.response);
      afterPerform(false);
    });
};

export const addShipment = (data, afterPerform) => {
  axios
    .post(url + `/admin/shipment`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addSupportCategory = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/support/category`, data)
    .then((res) => {
      getSupportCategories(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addTicket = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/support/tickets`, data)
    .then((res) => {
      getTickets(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateCoupon = (id, data, page, setData, afterPerform) => {
  axios
    .put(url + `/admin/coupon/${id}`, data)
    .then((res) => {
      getCoupons(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateAdminOrder = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/order/${id}`, data)
    .then((res) => {
      if (setData != null) getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updatePurchaseComplete = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/purchase-complete/${id}`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const completePurchase = (id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/purchase/complete-purchase/`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const userInvoiceDetails = ( data, setData) => {
  axios
    .post(url + `/admin/user-invoice`, data)
    .then((res) => {
      setData(res)
      // afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      // afterPerform(false);
    });
};
export const userInvoiceCreate = ( data, setData, afterPerform) => {
  axios
    .post(url + `/admin/create-invoice`, data)
    .then((res) => {
      setData(res)
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};





export const completeSorting = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/sorting-complete/${id}`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const orderItemUpdate = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/order-item/${id}`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      // console.log(err.response);
      afterPerform(false);
    });
};


export const updateSupportCategory = (
  id,
  data,
  page,
  setData,
  afterPerform
) => {
  axios
    .put(url + `/admin/support/category/${id}`, data)
    .then((res) => {
      getSupportCategories(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateTicket = (id, data, page, setData, afterPerform) => {
  axios
    .put(url + `/admin/support/tickets/${id}`, data)
    .then((res) => {
      getTickets(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateRolePermission = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/roles/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

// ZIMIZOM

export const addCategory = (data, setData, afterPerform) => {
  axios
    .post(url + `/admin/category`, data)
    .then((res) => {
      getCategories(setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const addTxnCategory = (data, afterPerform) => {
  axios
    .post(url + `/txn-category`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addHoliday = (data, afterPerform) => {
  axios
    .post(url + `/holiday`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const applyForLeave = (data, afterPerform) => {
  axios
    .post(url + `/leave`, data)
    .then((res) => {
      console.log(res.data);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, getError(err));
    });
};
export const approveLeave = (id, data, afterPerform) => {
  axios
    .put(url + `/leave/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, getError(err));
    });
};
export const addTag = (data, setData, afterPerform) => {
  axios
    .post(url + `/tag`, data)
    .then((res) => {
      getTags(setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const updateCategory = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/category/${id}`, data)
    .then((res) => {
      getCategories(setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateTxnCategory = (id, data, afterPerform) => {
  axios
    .put(url + `/txn-category/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateHoliday = (id, data, afterPerform) => {
  axios
    .put(url + `/holiday/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateLeave = (id, data, afterPerform) => {
  axios
    .put(url + `/leave/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateTag = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/tag/${id}`, data)
    .then((res) => {
      getTags(setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const deleteCategory = (id, setData, afterPerform) => {
  axios
    .delete(url + `/category/${id}`)
    .then((res) => {
      getCategories(setData);
      afterPerform(true);
    })
    .catch((err) => {
      let message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "";
      afterPerform(false, message);
    });
};
export const deleteMasterCategory = (id, afterPerform) => {
  axios
    .delete(url + `/master/category/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      let message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "";
      afterPerform(false, message);
    });
};

export const addProduct = (data, afterPerform) => {
  axios
    .post(url + `/product`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addMasterProduct = (data, afterPerform) => {
  axios
    .post(url + `/master/product`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateMasterProduct = (id, data, afterPerform) => {
  axios
    .put(url + `/master/product/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addVendor = (data, afterPerform) => {
  axios
    .post(url + `/admin/vendor`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateProduct = (id, data, afterPerform) => {
  axios
    .put(url + `/product/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const getProductById = (id, setData) => {
  axios
    .get(url + `/product/${id}`)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};

export const getDeliveryMethodDataJson = (setData, filter) => {
  // let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  console.log("filters", filter);
  axios
    .get("JsonData.json")
    .then((res) => {
      if (res.data) {
        // console.log('res.data.data', res.data.data)
        setData(res.data.data.filter((data) => data.status == filter.status && data.delivery_method == filter.method ));
      }
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};

export const getInvoiceDataJson = (setData, filter) => {
  // let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  // console.log("filters", filter);
  console.log('first' )
  axios
    .get("JsonData1.json")
    .then((res) => {
      // console.log('res', res)
      if (res.data) {
        // console.log('res.data.data', res.data.data)
        setData(res);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};




export const getVendorById = (id, setData) => {
  axios
    .get(url + `/vendor/${id}`)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};
export const getMasterProductsByPage = (page, category_id, setData, filter) => {
  let ext = `/master/products?category=${category_id}${filter}&page=${page}`;
  console.log(ext);
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};
export const getVendorsByPage = (page, setData, setLoading, filter) => {
  let ext = `/vendors?page=${page}&per_page=20`;
  if (filter) {
    ext = `/vendors?page=${page}&per_page=20${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};

export const getMyProfile = (setData) => {
  axios
    .get(url + `/admin/profile`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getVendor = (setData) => {
  axios
    .get(url + `/vendor`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const updateMyProfile = (data, afterAsync) => {
  axios
    .put(url + `/admin/profile`, data)
    .then((res) => {
      afterAsync(true);
    })
    .catch((err) => {
      afterAsync(false);
    });
};

export const updateVendor = (id, data, afterAsync) => {
  axios
    .put(url + `/admin/vendor/${id}`, data)
    .then((res) => {
      afterAsync(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterAsync(false);
    });
};



export const getUsersPie = (setData) => {
  axios
    .get('UserPiue.json')
    .then((res) => {
      if (res.data && res.data) setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getUsersColumn = (setData) => {
  axios
    .get('Column.json')
    .then((res) => {
      if (res.data && res.data) setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getUsersLine = (setData) => {
  axios
    .get('Line.json')
    .then((res) => {
      if (res.data && res.data) setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getUsers = (page, setData, filter) => {
  // console.log('filter', filter)
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/admins" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCustomSheet = (setData, filter, afterAsync) => {
  axios
    .post(url + "/admin/customs-sheet", filter)
    .then((res) => {
      if (res.data) setData(res.data.result);
      if (afterAsync) {
        afterAsync(res.data.result);
      }
    })
    .catch((err) => {
      setData([]);
    });
};
export const getPurchaseList = (setData, filter) => {
  // console.log('filter', filter)
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  // console.log('filters', filters)
  axios
    .get(url + `/admin/purchase-list` + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getShipmentDetails = (setData, filter, afterAsync) => {
  axios
    .post(url + "/admin/shipment-details", filter)
    .then((res) => {
      if (res.data) setData(res.data.result);
      if (afterAsync) {
        afterAsync(res.data.result);
      }
    })
    .catch((err) => {
      setData([]);
    });
};
export const getVendors = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/vendors" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCustomers = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/customers" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getShipments = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/shipments" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getPublicShipments = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/public/shipments" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSCNs = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/scns" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getBookings = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/bookings" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getUnreceivedBookings = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/unreceived-bookings" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getEmployees = (setData, setLoading, filter) => {
  let ext = `/employees`;
  if (filter) {
    ext = `/employees?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);

      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      if (setLoading) {
        setLoading(false);
      }
    });
};
export const getEmployeeRecord = (setData, setLoading, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/employee-records" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      setData([]);
      if (setLoading) {
        setLoading(false);
      }
    });
};
export const getIdeas = (setData, setLoading, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/ideas" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      setData([]);
      if (setLoading) {
        setLoading(false);
      }
    });
};
export const lookupCustomer = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/global-customer-search" + filters)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getTransactions = (setData, setLoading, filter) => {
  let ext = `/transactions`;
  if (filter) {
    ext = `/transactions?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      if (setLoading) {
        setLoading(false);
      }
    });
};
export const getTransactionStats = (setData, setLoading, filter) => {
  let ext = `/transactions/report/stats`;
  if (filter) {
    ext = `/transactions/report/stats?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      if (res.data && res.data) setData(res.data);
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const addUser = (data, setData, afterPerform) => {
  axios
    .post(url + `/user`, data)
    .then((res) => {
      getUsers(setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addEmployee = (data, afterPerform) => {
  axios
    .post(url + `/admin/admin`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const createBalanceTxn = (data, afterPerform) => {
  axios
    .post(url + `/admin/create-balance-txn`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const addEmployeeReport = (data, afterPerform) => {
  axios
    .post(url + `/employee-record`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addIdea = (data, afterPerform) => {
  axios
    .post(url + `/idea`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addTransaction = (data, afterPerform) => {
  axios
    .post(url + `/transaction`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const startMyDay = (data, afterPerform) => {
  axios
    .post(url + `/attendance/startday`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const startMyLunch = (data, afterPerform) => {
  axios
    .post(url + `/attendance/startlunch`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      console.log(getError(err));
      afterPerform(false, getError(err));
    });
};
export const endMyLunch = (data, afterPerform) => {
  axios
    .post(url + `/attendance/endlunch`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const endMyDay = (data, afterPerform) => {
  axios
    .post(url + `/attendance/endday`, data)
    .then((res) => {
      console.log(res.data);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateBatchScnStatus = (data, afterPerform) => {
  axios
    .post(url + `/admin/bulk-scn-status-update`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateBatchBookingStatus = (data, afterPerform) => {
  axios
    .post(url + `/admin/update-bulk-booking-status`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addUnreceivedNote = (data, afterPerform) => {
  axios
    .post(url + `/admin/booking-note`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateBulkScn = (data, afterPerform) => {
  axios
    .post(url + `/admin/bulk-scn-status-update`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const updateUnreceivedNote = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/booking-note/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const updateTransaction = (id, data, afterPerform) => {
  axios
    .put(url + `/transaction/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateCustomSheet = (data, afterPerform) => {
  axios
    .put(url + `/admin/customs-sheet`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateBooking = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/booking/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const confirmBooking = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/confirm-booking/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const sortBooking = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/sort-booking/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateEmployee = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/admin/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateEmployeeRecord = (id, data, afterPerform) => {
  axios
    .put(url + `/employee-record/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateIdea = (id, data, afterPerform) => {
  axios
    .put(url + `/idea/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const updateUser = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/user/${id}`, data)
    .then((res) => {
      getUsers(setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};


// export const getDrivers = (setData) => {
//   axios
//     .get(url + `/drivers`)
//     .then((res) => {
//       if (res.data && res.data.result) setData(res.data.result);
//     })
//     .catch((err) => {
//       setData([]);
//     });
// };

export const getDrivers = (setData, filter) => {
  let ext = `/drivers`;
  if (filter) {
    ext = `/drivers?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      if (res.data && res.data.result) setData(res.data.result);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};

export const addDriver = (data, setData, afterPerform) => {
  axios
    .post(url + `/driver`, data)
    .then((res) => {
      getDrivers(setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updateDriver = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/driver/${id}`, data)
    .then((res) => {
      getDrivers(setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const getNotification = (setData) => {
  axios
    .get(url + `/notification`)
    .then((res) => {
      setData(res.data?.result);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getMyRecords = (setData) => {
  axios
    .get(url + `/my-records`)
    .then((res) => {
      setData(res.data?.result);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};

export const getShipmentById = (id, setData) => {
  axios
    .get(url + `/admin/shipment/${id}`)
    .then((res) => {
      setData(res.data.result);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCallReport = (filter, setData) => {
  axios
    .get(url + `/call-log-summary`)
    .then((res) => {
      setData(res.data?.result);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getTodaysAttendance = (setData) => {
  axios
    .get(url + `/attendance/mytoday`)
    .then((res) => {
      setData(res.data);
      console.log(res.data.attendance);
      if (res.data.attendance) {
        localStorage.setItem("last", moment(new Date()).format("DD/MM/YY"));
      }
    })
    .catch((err) => {
      setData(null);
    });
};

export const getTickets = (setData, page, platform, id, status) => {
  let ext = `/tickets?platform=${platform}&customer=${id}&status=${status}&page=${page}`;
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCallLogs = (setData, page, platform, id, employee) => {
  let ext = `/call-logs?customer=${id}&page=${page}&platform=${platform}&added_by=${employee}`;
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const addCallLog = (data, afterPerform) => {
  axios
    .post(url + `/call-log`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const getEarnings = (setData, page, filter) => {
  let ext = `/earnings?page=${page}&per_page=20`;
  if (filter) {
    ext = `/earnings?page=${page}&per_page=20${filter}`;
  }
  console.log(ext);
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};
export const getMyAttendanceReport = (setData, filter) => {
  console.log(filter);
  let ext = `/attendance/report/monthly`;
  if (filter) {
    ext = `/attendance/report/monthly?${filter}`;
  }
  console.log(ext);
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};
export const getAttendanceSummery = (setData, filter) => {
  console.log(filter);
  let ext = `/attendance/summary/monthly`;
  if (filter) {
    ext = `/attendance/summary/monthly?${filter}`;
  }
  console.log(ext);
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};
export const getDailyAttendanceReport = (setData, filter) => {
  let ext = `/attendance/report/daily`;
  if (filter) {
    ext = `/attendance/report/daily?${filter}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};
export const getMonthlyAttendanceReport = (setData, filter) => {
  let ext = `/attendance/report/employee/monthly`;
  if (filter) {
    ext = `/attendance/report/employee/monthly`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};

export const addOrderNote = (data, setData, afterPerform) => {
  axios
    .post(url + `/admin/order-note`, data)
    .then((res) => {
      // console.log(res.data);
      setData != null && getOrderById(data.order, setData);

      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updateOrderNote = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/order-note/${id}`, data)
    .then((res) => {
      // console.log(res.data);
      // getOrderById(data.order, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addBookingNote = (data, afterPerform) => {
  axios
    .post(url + `/admin/booking-note`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addBookingMeta = (data, afterPerform) => {
  axios
    .post(url + `/admin/booking-meta`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const deleteOrderNote = (data, setData, afterPerform) => {
  axios
    .delete(url + `/ordernote/${data.order_id}/${data.node_id}`, data)
    .then((res) => {
      console.log(res.data);
      getOrderById(data.order_id, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const getOrderById = (id, setData) => {
  // console.log('idssssss', id)
  axios
    .get(url + `/admin/order/${id}`)
    .then((res) => {
      console.log("res", res);
      setData(res.data);
    })
    .catch((err) => {
      console.log("err", err);
      setData(null);
    });
};
export const getBookingById = (id, setData) => {
  axios
    .get(url + `/admin/booking/${id}`)
    .then((res) => {
      setData(res.data?.result);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getAllTransaction = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/transactions" + filters)
    .then((res) => {
      setData(res.data.result);
    })
    .catch((err) => console.log(err));
};

export const getAllPermissions = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + "/admin/permissions" + filters)
    .then((res) => {
      setData(res.data.result);
    })
    .catch((err) => console.log(err));
};

export const updateRoles = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/permission/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      // console.log(err.response);
      afterPerform(false);
    });
};

export const logout = () => {
  axios
    .get(url + `/admin/logout`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendCustomerMessage = (setData, page, id) => {};
