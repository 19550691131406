import {
  LogoutOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { logoutAdmin } from "../Actions/authAction";
import { isPermitted } from "../utils/PermissionManager";
import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function SideBar(props) {
  const roleNow = useSelector((state) => state.config.roleActive);

  // console.log('roleNow', roleNow)
  const dispatch = useDispatch();
  const history = useHistory();
  const path = history.location.pathname;
  const screens = useBreakpoint();
  const location = useLocation();
  const { show, setshow } = props.state;
  const roles = useSelector((state) => state.roles.roles);

  const profile = localStorage.getItem("pr");
  let profileData = profile ? JSON.parse(profile) : null;
  let dev_server = window.location.hostname === "localhost" ? true : false;
  // console.log('dev_server', dev_server)
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  // console.log("update", update);
  // adminRole

  useEffect(() => {

    if (screens.xs) {
      setshow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, screens]);

  // console.log('roles.includes(roleNow)', sidebarItems.filter((item) => ))
  

  return (
    <>
      {location.pathname !== "/login" && !location.pathname.includes("public") && (
        <Sider
          trigger={null}
          breakpoint="xxl"
          onBreakpoint={(broken) => {
            setshow(broken);
          }}
          width="200px"
          style={{
            overflow: "auto",
            height: "calc(100vh - 60px)",
            position: "fixed",
            top: "60px",
            zIndex: 1,
            left: 0,
            bottom: 0,
            paddingTop: "0.5rem",
            backgroundColor: "white",
            fontWeight: "bold",
            // borderRight: "1px solid rgba(0,0,0,.1)",
          }}
          collapsedWidth="0"
          collapsed={show}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[path.toString()]}
            defaultOpenKeys={["accounts"]}
          >
            {sidebarItems.map((sidebar) => {
              // console.log('sidebar.permiss', sidebar.permissions)
              // console.log('item.permissions.includes(roleNow)', sidebar.permissions.includes(roleNow))
              // && dev_server === sidebar.dev 
              if (isPermitted(roles, sidebar.permissions) && ((sidebar.permissions.includes("all") || !isPermitted(roles,['admin']) ) || sidebar.permissions.includes(roleNow))) {

                return (
                  <Menu.Item key={sidebar.path} icon={sidebar.icon}>
                    <Link to={sidebar.path}>{sidebar.name}</Link>
                  </Menu.Item>
                );
              }
            })}

            {isPermitted(roles, ["admin"]) && (
              <>
                <Menu.ItemGroup
                  key="g4"
                  title={<b style={{ color: "grey" }}>Admin</b>}
                >
                  <Menu.Item
                    key="/employees"
                    icon={
                      <UsergroupAddOutlined
                        style={{ fontSize: "14px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/employees"}>Employees</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/roles"
                    icon={
                      <UserSwitchOutlined
                        style={{ fontSize: "14px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/roles"}>Roles</Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              </>
            )}

            <Menu.ItemGroup
              key="g5"
              title={<b style={{ color: "grey" }}>Authentication</b>}
            >
              <Menu.Item
                key="/SettingOutlined "
                icon={
                  <UserOutlined style={{ fontSize: "14px", width: "18px" }} />
                }
              >
                <Link to={"/profile"}>Profile</Link>
              </Menu.Item>
              <Menu.Item
                key="/logout"
                onClick={() => {
                  dispatch(logoutAdmin());
                  openNotificationWithIcon("success", "Success", "Logged out successfully");
                }}
                icon={
                  <LogoutOutlined style={{ fontSize: "16px", width: "18px" }} />
                }
              >
                Logout
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Sider>
      )}
    </>
  );
}
