const initialState = {
  config: null,
  roleActive: null,
};
export default function config(state = initialState, action) {
  switch (action.type) {
    case "SET_CONFIG":
      return {
        ...state,
        config: action.payload,
      };
    case "SET_ROLE_NOW":
      return {
        ...state,
        roleActive: action.payload,
      };
    default:
      return state;
  }
}
