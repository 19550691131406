import { MenuOutlined, UserOutlined, BellOutlined } from "@ant-design/icons";
import { Avatar, Badge, Drawer, Layout, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { setConfiguration, setRoleNow } from "../Actions/configAction";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import logo from "../assets/logo.png";
import { isPermitted } from "../utils/PermissionManager";
const { Header } = Layout;
const { Text } = Typography;

export default function Navbar({ state }) {
  const profile = localStorage.getItem("pr");
  const roles = useSelector((state) => state.roles.roles);
  let profileData = profile ? JSON.parse(profile) : null;
  // console.log('profile', profile)
  const location = useLocation();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setConfiguration());
    dispatch(setRoleNow(profileData?.adminRole[0]));
  }, [dispatch]);

  // console.log("profileData", profileData);
  // console.log(
  //   location.pathname !== "/login" || !location.pathname.includes("public")
  // );
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title style={{ textTransform: "capitalize" }}>
          Onesalemart{" - "}
          {location.pathname.slice(1)?.split("-")?.join(" ")}
        </title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {location.pathname !== "/login" && !location.pathname.includes("public") && (
        <Header
          style={{
            background: "linear-gradient(90deg, #002766 0%, #003a8c 100%, #0050b3 30%)",
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23ececec' fill-opacity='0.18' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E")`,a(0,10%,100%,0.9)`,
            padding: screens.xs ? "0px 0.75rem" : "0px 1.5rem",
            position: "fixed",
            width: "100%",
            // boxShadow: "0 1px 16px rgba(0,0,0,.1)",
            borderBottom: "1px solid rgba(0,0,0,0.08)",
            zIndex: "1",
            height: "60px",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="menuShow"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MenuOutlined
                  style={{
                    fontSize: "18px",
                    padding: "0.5rem",
                    borderRadius: "2px",
                    marginRight: "0.5rem",
                    color: "white",
                  }}
                  onClick={() => {
                    state.setshow((state) => !state);
                    state.settrigger((state) => !state);
                  }}
                />
              </div>
              <a
                href="/"
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img style={{ height: "30px" }} src={logo} alt="" />
              </a>
            </div>
            {screens.xs ? 
            
          "":
            <div style={{ display: "flex", alignItems: "center" }}>
             
              <Select
                // defaultValue="lucy"
                // value={profileData?.adminRole[]}
                defaultValue={profileData?.adminRole[0]}
                onChange={(e) => {
                  if(isPermitted(roles,["admin"])){
                    dispatch(setRoleNow(e))
                  }
                  console.log(e);

              
                }}
                style={{
                  width: 120,
                }}
                // disabled
              >
                {profileData?.adminRole?.length > 0 &&
                  profileData?.adminRole?.map((item, index) => (
                    <Select.Option  value={item} key={index}>{item}</Select.Option>
                  ))}


              </Select>
              <Link to="/profile" className="logout">
                <Avatar
                  style={{ backgroundColor: "#eee", cursor: "pointer" }}
                  icon={<UserOutlined style={{ color: "black" }} />}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "0.5rem",
                  }}
                >
                  <Text
                    style={{
                      lineHeight: "18px",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    {(profileData && profileData?.employeeName) ||
                      profileData?.adminName}
                  </Text>
                  <Text
                    style={{
                      lineHeight: "18px",
                      fontSize: "12px",
                      color: "#fff",
                    }}
                  >
                    {(profileData && profileData.employeePhone) ||
                      profileData?.adminPhone}
                  </Text>
                </div>
              </Link>
            </div>
          }
          </div>
        </Header>
      )}
    </>
  );
}
